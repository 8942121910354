define(['santa-components', 'lodash', 'zepto', 'coreUtils'], function (santaComponents, _, $, coreUtils) {
    'use strict';

    function isSelectedItem(dataItem) {
        return dataItem.isSelected;
    }

    function triggerClickOnAnchor(event) {
        const anchor = $(event.target).find('a')[0];

        if (anchor) {
            anchor.click();
            return false;
        }

        return true;
    }

    function getItemProperties(className, classPrefix, selected, heights, id, hoverId, callbacks, hasVisibleItems, isDesktop) {
        const classSet = {};
        classSet[`${classPrefix}_selected`] = selected;
        classSet[`${classPrefix}_hover`] = coreUtils.stringUtils.startsWith(hoverId, id);
        classSet[className] = true;

        const itemProperties = {
            className: coreUtils.classNames(classSet),
            style: {
                minHeight: Math.min(heights.line, heights.item),
                height: heights.item
            },
            onClick: hasVisibleItems ? callbacks.click : callbacks.leave,
            id,
            key: id
        };

        if (isDesktop) {
            _.assign(itemProperties, {
                onMouseEnter: callbacks.enter,
                onMouseLeave: callbacks.leave,
                onFocus: callbacks.focus,
                onBlur: callbacks.blur,
                onKeyDown: callbacks.keyDown
            });
        }

        return itemProperties;
    }

    function getItemsContainerProperties(hasVisibleItems, className, classPrefix, separatorHeight) {
        return {
            style: {
                marginBottom: hasVisibleItems ? `${separatorHeight}px` : ''
            },
            className: hasVisibleItems ? className : `${className} ${classPrefix}_emptySubMenu`
        };
    }

    function getLabelProperties(className, classPrefix, heights, link, level, callbacks, hasVisibleItems) {
        const properties = {
            className: `${className} ${classPrefix}_label level${level}`,
            style: {
                lineHeight: `${Math.min(heights.line, heights.item)}px`
            },
            role: 'button',
            'aria-haspopup': hasVisibleItems,
            onClick: hasVisibleItems ? callbacks.click : callbacks.leave
        };

        _.merge(properties, link ? link.render : {tabIndex: 0});

        return properties;
    }

    function getCounterProperties(classPrefix, heights, link, level, callbacks, hasVisibleItems, counterClassName) {
        const labelProps = getLabelProperties('', classPrefix, heights, link, level, callbacks, hasVisibleItems);
        labelProps.className = `${classPrefix}_${counterClassName} counter`;
        return labelProps;
    }

    function additionalActions(properties) {
        if (_.includes(properties.className, 'Wrapper')) {
            properties.onClick = triggerClickOnAnchor;
        }
    }

    function pushCounterWithProperties(items, classPrefix, heights, link, level, callbacks, hasVisibleItems, counterClassName, displayCount) {
        if (_.isNumber(displayCount)) {
            const counterProps = getCounterProperties(classPrefix, heights, link, level, callbacks, hasVisibleItems, counterClassName);
            items.push(santaComponents.utils.createReactElement('span', counterProps, `(${displayCount})`));
        }
    }

    const domBuilder = {
        getSkin(skinsMap, skinName, isExperimentOpen) {
            return skinsMap.get(skinName, isExperimentOpen);
        },
        buildTemplate(skinItem, classPrefix) {
            const rawItems = skinItem.slice(3, skinItem.length),
                items = [];

            _.forEach(rawItems, child => {
                if (_.isArray(child)) {
                    items.push(this.buildTemplate(child, classPrefix));
                }
            });

            return {
                tag: skinItem[0].toLowerCase(),
                skinPart: skinItem[1],
                className: _.map(skinItem[2], currentClass => classPrefix + currentClass),
                items
            };
        },
        buildDomItem(mainTemplate, template, dataItems, dataItem, classPrefix, currentPage, heights, level, parentId, hoverId, callbacks, isDesktop, counterClassName, itemsAlignment) {
            const tag = santaComponents.utils.createReactElement.bind(null, template.tag);
            const items = [];
            let properties = {
                className: template.className.concat(dataItem.link ? '' : `${classPrefix}_noLink`).join(' ')
            };
            const id = `${parentId + dataItems.indexOf(dataItem)}_`;
            const hasVisibleItems = _.filter(dataItem.items, {isVisible: true}).length > 0;

            switch (template.tag) {
                case 'a':
                    if (itemsAlignment === 'right') {
                        pushCounterWithProperties(items, classPrefix, heights, dataItem.link, level, callbacks, hasVisibleItems, counterClassName, dataItem.displayCount);
                        items.push(dataItem.label);
                        properties = getLabelProperties(properties.className, classPrefix, heights, dataItem.link, level, callbacks, hasVisibleItems);
                    } else {
                        items.push(dataItem.label);
                        properties = getLabelProperties(properties.className, classPrefix, heights, dataItem.link, level, callbacks, hasVisibleItems);
                        pushCounterWithProperties(items, classPrefix, heights, dataItem.link, level, callbacks, hasVisibleItems, counterClassName, dataItem.displayCount);
                    }
                    break;
                case 'ul':
                    _.forEach(dataItem.items, subItem => { //eslint-disable-line lodash3/prefer-filter
                        if (subItem.isVisible) {
                            items.push(this.buildDomItem(mainTemplate, mainTemplate, dataItem.items, subItem, classPrefix, currentPage, heights, level + 1, id, hoverId, callbacks, isDesktop, counterClassName, itemsAlignment));
                        }
                    });

                    properties = getItemsContainerProperties(hasVisibleItems, properties.className, classPrefix, heights.separator);
                    break;
                case 'li':
                    _.forEach(template.items, templateItem => {
                        items.push(this.buildDomItem(mainTemplate, templateItem, dataItems, dataItem, classPrefix, currentPage, heights, level, parentId, hoverId, callbacks, isDesktop, counterClassName, itemsAlignment));
                    });
                    properties = getItemProperties(properties.className, classPrefix, isSelectedItem(dataItem), heights, id, hoverId, callbacks, hasVisibleItems, isDesktop);
                    break;
                default:
                    _.forEach(template.items, templateItem => {
                        items.push(this.buildDomItem(mainTemplate, templateItem, dataItems, dataItem, classPrefix, currentPage, heights, level, parentId, hoverId, callbacks, isDesktop, counterClassName, itemsAlignment));
                    });
                    break;
            }

            additionalActions(properties);

            if (template.tag === 'ul') {
                return tag(properties, items);
            }

            return tag.apply(null, [properties].concat(items));
        },
        buildDOMFromTemplate(template, dataItems, classPrefix, currentPage, heights, hoverId, callbacks, isDesktop, counterClassName, itemsAlignment) {
            const mainTemplate = template.items[0];
            const domItems = _(dataItems)
                .filter('isVisible')
                .map(item =>
                    this.buildDomItem(mainTemplate, mainTemplate, dataItems, item, classPrefix, currentPage, heights, 0, 'root', hoverId, callbacks, isDesktop, counterClassName, itemsAlignment)
                ).value();

            return santaComponents.utils.createReactElement('ul', {
                className: `${classPrefix}menuContainer`
            }, domItems);
        }
    };

    return domBuilder;
});
