define([
    'santa-components',
    'zepto',
    'lodash',
    'react',
    'create-react-class',
    'componentsCore',
    'coreUtils',
    'verticalMenu/components/verticalMenuItem',
    'reactDOM',
    'comboBoxInput',
    'skins',
    'verticalMenu/skins/skins.json'
], function (santaComponents, $, _, React, createReactClass, componentsCore, coreUtils, verticalMenuItem, ReactDOM, comboBoxInput, skinsPackage, skinsJson) {
    'use strict';

    const linkRenderer = coreUtils.linkRenderer;


    const verticalMenuItemInstance = React.createFactory(createReactClass(verticalMenuItem));

    function onMouseClick(event) {
        const liParent = getLIParent(event.target);
        const newHoverId = liParent.id;
        const hasVisibleItems = $(liParent).find('ul')[0].children.length > 0;
        const isAlreadyHovered = newHoverId === this.state.hoverId;

        if (hasVisibleItems) {
            if (isAlreadyHovered) {
                this.setState({hoverId: null});
            } else {
                this.setState({hoverId: newHoverId});
                event.preventDefault();
            }
        } else {
            this.setState({hoverId: null});
        }
    }

    function openSubMenu(event) {
        const hoveredNodeId = getLIParent(event.target).id;
        if (hoveredNodeId !== this.state.hoverId) {
            this.setState({hoverId: hoveredNodeId});
        }
    }

    function closeSubMenu() {
        this.setState({hoverId: null});
    }

    function getLIParent(node) {
        while (node.tagName !== 'LI') {
            node = node.parentElement;
        }

        return node;
    }

    function flattenMenuItems(items) {
        const flatItems = _.reduce(items, function (result, item) {
            result.push(item);
            if (item.items) {
                result = result.concat(flattenMenuItems(item.items));
            }

            return result;
        }, []);

        return _.filter(flatItems, 'link');
    }

    function getMenuItemValue(item) {
        if (item.link && item.link.type === 'PageLink') {
            return item.link.pageId.id;
        }

        if (item.link && item.link.type === 'DynamicPageLink') {
            return item.link.innerRoute;
        }
        return item.label;
    }

    function getItemText(item, itemsAlignment) {
        if (_.isNumber(item.displayCount)) {
            if (itemsAlignment === 'right') {
                return `(${item.displayCount}) ${item.label}`;
            }
            return `${item.label} (${item.displayCount})`;
        }

        return item.label;
    }

    function convertMenuItemsToComboBoxItems(menuItems, itemsAlignment) {
        return _.map(menuItems, function (item) {
            const itemText = getItemText(item, itemsAlignment);
            const properties = {
                isSelected: item.isSelected,
                text: itemText,
                key: item.id,
                value: getMenuItemValue(item),
                link: item.link ? linkRenderer.renderLink(item.link, this.props.linkRenderInfo, this.props.rootNavigationInfo) : item.label
            };
            return properties;
        }.bind(this));
    }

    function getSelectedPage(menuItems) {
        const selectedMenuItem = _.find(menuItems, 'isSelected');

        return selectedMenuItem ? selectedMenuItem.value : '';
    }

    function onSelectionChange(event) {
        const linkToNavigate = event.payload.link;
        const navigationBlocked = !this.props.navigateToRenderedLink(linkToNavigate);
        if (navigationBlocked && this.props.previewTooltipCallback) {
            const targetClientRect = event.target.getBoundingClientRect();
            this.props.previewTooltipCallback(targetClientRect, 'text_editor_inactive_link_on_preview');
        }
    }


    /**
     * @class components.verticalMenu
     * @extends {core.skinBasedComp}
     * @extends {componentsCore.skinInfo}
     */
    const verticalMenu = {
        displayName: 'VerticalMenu',
        mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.skinInfo, componentsCore.mixins.createChildComponentMixin],
        propTypes: _.assign({
            isMobileDevice: santaComponents.santaTypesDefinitions.Device.isMobileDevice,
            isTabletDevice: santaComponents.santaTypesDefinitions.Device.isTabletDevice,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView,
            menuItems: santaComponents.santaTypesDefinitions.Menu.menuItems,
            currentUrlPageId: santaComponents.santaTypesDefinitions.Component.currentUrlPageId.isRequired,
            navigateToRenderedLink: santaComponents.santaTypesDefinitions.Navigation.navigateToRenderedLink.isRequired,
            linkRenderInfo: santaComponents.santaTypesDefinitions.Link.renderInfo,
            rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo,
            previewTooltipCallback: santaComponents.santaTypesDefinitions.RenderRealtimeConfig.previewTooltipCallback,
            compData: santaComponents.santaTypesDefinitions.Component.compData,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp,
            styleId: santaComponents.santaTypesDefinitions.Component.styleId,
            skin: santaComponents.santaTypesDefinitions.Component.skin,
            style: santaComponents.santaTypesDefinitions.Component.style,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
            scale: santaComponents.santaTypesDefinitions.Component.scale
        }, santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(comboBoxInput)),

        statics: {
            compSpecificIsDomOnlyOverride: (prevProps, nextProps) =>
                nextProps.style.height === prevProps.style.height // height is passed as props to children slides

        },
        subMenuTabbableItems: false,
        getInitialState() {
            return {
                $subMenuOpenSide: `subMenuOpenSide-${this.props.compProp.subMenuOpenSide}`,
                $itemsAlignment: `items-align-${this.props.compProp.itemsAlignment}`,
                $subItemsAlignment: `subItems-align-${this.props.compProp.itemsAlignment}`,
                hoverId: null,
                $mobile: this.props.isMobileView ? 'mobile' : 'notMobile'
            };
        },
        componentWillReceiveProps(nextProps) {
            if (nextProps.compProp.itemsAlignment !== this.props.compProp.itemsAlignment) {
                this.setState({
                    $itemsAlignment: `items-align-${nextProps.compProp.itemsAlignment}`,
                    $subItemsAlignment: `subItems-align-${nextProps.compProp.itemsAlignment}`
                });
            }
            if (nextProps.compProp.subMenuOpenSide !== this.props.compProp.subMenuOpenSide) {
                this.setState({$subMenuOpenSide: `subMenuOpenSide-${nextProps.compProp.subMenuOpenSide}`});
            }

            if (nextProps.isMobileView !== this.props.isMobileView) {
                this.setState({$mobile: this.props.isMobileView ? 'mobile' : 'notMobile'});
            }
        },
        updateDOMDataAttributes() {
            const domNode = ReactDOM.findDOMNode(this);
            const params = this.getParamValues();
            this.lastParams = params;
            domNode.setAttribute('data-param-border', params.border);
            domNode.setAttribute('data-param-separator', params.separator);
            domNode.setAttribute('data-param-padding', params.padding);
        },
        componentDidMount() {
            this.updateDOMDataAttributes();
        },
        componentDidUpdate() {
            if (!_.isEqual(this.getParamValues(), this.lastParams)) {
                this.updateDOMDataAttributes();
            }
        },
        getParamValues() {
            const separatorParam = this.getParamFromDefaultSkin('separatorHeight') || this.getParamFromDefaultSkin('sepw');
            const paddingParam = this.getParamFromDefaultSkin('textSpacing');
            const borderParam = this.getParamFromDefaultSkin('brw');

            return {
                separator: separatorParam ? parseInt(separatorParam.value, 10) : 0,
                border: borderParam ? parseInt(borderParam.value, 10) : 0,
                padding: paddingParam ? parseInt(paddingParam.value, 10) : 0
            };
        },

        handleKeyDownForMainMenu(event) { // eslint-disable-line complexity
            let tabbableElements = componentsCore.utils.accessibility.getTabbaleElements(ReactDOM.findDOMNode(this));
            const liParent = getLIParent(event.target);
            const subMenu = _.head($(liParent).find('ul'));
            let nextIndexToFocus;
            let allTabbableElementsOnDocument;
            if (subMenu) {
                const subMenutabbableElements = componentsCore.utils.accessibility.getTabbaleElements(subMenu);
                tabbableElements = _.difference(tabbableElements, subMenutabbableElements);
            }

            if (event.key === 'ArrowDown' || !event.shiftKey && event.key === 'Tab') { // eslint-disable-line no-mixed-operators
                event.stopPropagation();
                event.preventDefault();
                nextIndexToFocus = _.indexOf(tabbableElements, event.target) + 1;
                if (nextIndexToFocus === tabbableElements.length) {
                    allTabbableElementsOnDocument = componentsCore.utils.accessibility.getTabbaleElements(window.document);
                    nextIndexToFocus = _.indexOf(allTabbableElementsOnDocument, event.target) + 1;
                    if (nextIndexToFocus === allTabbableElementsOnDocument.length) {
                        nextIndexToFocus = 0;
                    }
                    allTabbableElementsOnDocument[nextIndexToFocus].focus();
                    return;
                }
                tabbableElements[nextIndexToFocus].focus();
            }
            if (event.key === 'ArrowUp' || event.shiftKey && event.key === 'Tab') { // eslint-disable-line no-mixed-operators
                event.stopPropagation();
                event.preventDefault();
                nextIndexToFocus = _.indexOf(tabbableElements, event.target) - 1;
                if (nextIndexToFocus < 0) {
                    allTabbableElementsOnDocument = componentsCore.utils.accessibility.getTabbaleElements(window.document);
                    nextIndexToFocus = _.indexOf(allTabbableElementsOnDocument, event.target) - 1;
                    if (nextIndexToFocus < 0) {
                        nextIndexToFocus = allTabbableElementsOnDocument.length - 1;
                    }
                    allTabbableElementsOnDocument[nextIndexToFocus].focus();
                    return;
                }
                tabbableElements[nextIndexToFocus].focus();
            }
        },

        handleKeyDownForSubMenu(event) { // eslint-disable-line complexity
            let nextIndexToFocus;
            if (event.key === 'ArrowDown' || !event.shiftKey && event.key === 'Tab') { // eslint-disable-line no-mixed-operators
                event.stopPropagation();
                event.preventDefault();
                nextIndexToFocus = _.indexOf(this.subMenuTabbableItems, event.target) + 1;
                if (nextIndexToFocus === this.subMenuTabbableItems.length) {
                    nextIndexToFocus = 0;
                }
                this.subMenuTabbableItems[nextIndexToFocus].focus();
            }
            if (event.key === 'ArrowUp' || event.shiftKey && event.key === 'Tab') { // eslint-disable-line no-mixed-operators
                event.stopPropagation();
                event.preventDefault();
                nextIndexToFocus = _.indexOf(this.subMenuTabbableItems, event.target) - 1;
                if (nextIndexToFocus < 0) {
                    nextIndexToFocus = this.subMenuTabbableItems.length - 1;
                }
                this.subMenuTabbableItems[nextIndexToFocus].focus();
            }
            if (event.key === 'Escape') {
                event.stopPropagation();
                event.preventDefault();
                this.lastFocusedMainMenuItem.focus();
                this.subMenuTabbableItems = null;
                closeSubMenu.call(this);
            }
        },

        menuItemKeyDownHandler(event) { // eslint-disable-line complexity
            const shouldFocusSubMenu = (
                (// eslint-disable-line no-mixed-operators
                    (event.key === 'ArrowRight' && this.props.compProp.subMenuOpenSide === 'right' || event.key === 'ArrowLeft' && this.props.compProp.subMenuOpenSide === 'left')) // eslint-disable-line no-mixed-operators
            ) && !this.subMenuTabbableItems;
            if (shouldFocusSubMenu) {
                event.stopPropagation();
                event.preventDefault();
                const liParent = getLIParent(event.target);
                const subMenu = _.head($(liParent).find('ul'));
                if (subMenu) {
                    const tabbableElements = componentsCore.utils.accessibility.getTabbaleElements(subMenu);
                    _.head(tabbableElements).focus();
                    this.subMenuTabbableItems = tabbableElements;
                    this.lastFocusedMainMenuItem = event.target;
                }
                return;
            }
            if (!this.subMenuTabbableItems) {
                this.handleKeyDownForMainMenu(event);
            } else {
                this.handleKeyDownForSubMenu(event);
            }
        },

        getSkinProperties() {
            const params = this.getParamValues();
            const skinExports = this.getSkinExports();
            const menuItems = this.props.menuItems;
            const itemHeight = this.props.compProp.menuItemHeight;

            if (this.props.isMobileView) {
                const flatMenuItems = flattenMenuItems(menuItems);
                const comboBoxItems = convertMenuItemsToComboBoxItems.call(this, flatMenuItems, this.props.compProp.itemsAlignment);
                return {
                    '': {
                        tagName: 'nav'
                    },
                    menuContainer: this.createChildComponent(
                        {options: comboBoxItems, value: getSelectedPage(comboBoxItems), id: 'menuContainer'},
                        'wysiwyg.viewer.components.inputs.ComboBoxInput',
                        'menuContainer',
                        {
                            onSelectionChange: onSelectionChange.bind(this),
                            scale: this.props.scale,
                            compProp: {
                                class: 'mobileMenuContainer',
                                collectionClass: 'mobileCollection',
                                textAlignment: this.props.compProp.itemsAlignment,
                                textPadding: 20,
                                placeholder: {
                                    text: 'Choose a page',
                                    value: 'Choose a page'
                                }
                            },
                            setRuntimeCompData: _.noop,
                            setRuntimeCompProps: _.noop
                        }
                    )

                };
            }

            return {
                '': {
                    tagName: 'nav',
                    style: {
                        height: ''
                    }
                },
                menuContainer: {
                    parentConst: verticalMenuItemInstance,
                    data: menuItems,
                    skin: this.props.skin,
                    classPrefix: this.props.styleId,
                    currentUrlPageId: this.props.currentUrlPageId,
                    heights: {
                        separator: skinExports && skinExports.separatorNotIncludedInLineHeight ? 0 : params.separator,
                        line: coreUtils.verticalMenuCalculations.getLineHeight(itemHeight, params.separator, params.border, skinExports),
                        item: itemHeight
                    },
                    callbacks: {
                        click: onMouseClick.bind(this),
                        enter: openSubMenu.bind(this),
                        leave: closeSubMenu.bind(this),
                        focus: openSubMenu.bind(this),
                        blur: closeSubMenu.bind(this),
                        keyDown: this.menuItemKeyDownHandler
                    },
                    hoverId: this.state.hoverId,
                    isDesktop: !(this.props.isMobileDevice || this.props.isTabletDevice()),
                    counterClassName: 'counter',
                    itemsAlignment: this.props.compProp.itemsAlignment,
                    isExperimentOpen: this.props.isExperimentOpen
                }
            };
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.common.components.verticalmenu.viewer.VerticalMenu', verticalMenu);
    skinsPackage.skinsMap.addBatch(skinsJson);

    return verticalMenu;
});
