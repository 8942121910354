(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.editorSkinsData = f()}})(function(){var define,module,exports;
/* Autogenerated file. Do not modify */
'use strict';
var skins = {};
 skins['wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuArrowSkin'] = {
  "react": [
    [
      "ul",
      "menuContainer",
      [],
      {},
      [
        "li",
        "menuItem",
        [
          "_item"
        ],
        {},
        [
          "div",
          null,
          [
            "_itemWrapper"
          ],
          {},
          [
            "a",
            null,
            [],
            {}
          ],
          [
            "ol",
            null,
            [
              "_subMenu"
            ],
            {}
          ]
        ],
        [
          "div",
          null,
          [
            "_arrowWrapper"
          ],
          {},
          [
            "span",
            null,
            [
              "_arrow",
              "_arrowTop"
            ],
            {}
          ],
          [
            "span",
            null,
            [
              "_arrow",
              "_arrowBottom"
            ],
            {}
          ]
        ]
      ],
      [
        "li",
        "subMenuItem",
        [
          "_item"
        ],
        {},
        [
          "a",
          null,
          [],
          {}
        ],
        [
          "ul",
          null,
          [],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "menuContainer": {
      "skin": "ComboBoxInputSkin",
      "dontMergeParams": true
    }
  },
  "params": {
    "shd": "BOX_SHADOW",
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "itemBGColorTrans": "TRANSITION",
    "textSpacing": "PADDING_SIZE",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "fntDrop": "FONT",
    "bgh": "BORDER_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "bgs": "BORDER_COLOR_ALPHA",
    "txts": "TEXT_COLOR",
    "rdTop": "BORDER_RADIUS",
    "rdBottom": "BORDER_RADIUS",
    "dropDownRdTop": "BORDER_RADIUS",
    "dropDownRdBottom": "BORDER_RADIUS",
    "subMenuOpacityTrans": "TRANSITION",
    "bgDrop": "BG_COLOR_ALPHA",
    "dropdownBR": "BORDER_RADIUS",
    "dropdownMargin": "MARGIN_SIZE",
    "subItemAlterAlignPad": "SIZE"
  },
  "paramsDefaults": {
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "rd": "0",
    "bg": "color_11",
    "itemBGColorTrans": "background-color 0.4s ease 0s",
    "textSpacing": "10px",
    "fnt": "font_1",
    "txt": "color_15",
    "fntDrop": "font_1",
    "bgh": "color_15",
    "txth": "color_13",
    "bgs": "color_15",
    "txts": "color_13",
    "rdTop": "rd",
    "rdBottom": "rd",
    "dropDownRdTop": "dropdownBR",
    "dropDownRdBottom": "dropdownBR",
    "subMenuOpacityTrans": "all 0.4s ease 0s",
    "bgDrop": "color_11",
    "dropdownBR": "0",
    "dropdownMargin": "1px",
    "subItemAlterAlignPad": [
      "textSpacing"
    ]
  },
  "paramsMutators": {
    "subItemAlterAlignPad": {
      "type": "increase",
      "value": 0,
      "param": "textSpacing"
    }
  },
  "css": {
    "% > ul": "display:table;width:100%;box-sizing:border-box;",
    "% li": "display:table;width:100%;",
    "% a span": "pointer-events:none;",
    "%_noLink": "cursor:default !important;",
    "%_counter": "margin:0 10px;opacity:0.6;",
    "%menuContainer": "padding:0;margin:0;width:100%;height:100%;position:relative;[shd]  [rd]",
    "%menuContainer %_emptySubMenu": "display:none;",
    "%_item": "background-color:[bg];margin:0;position:relative;display:block;cursor:pointer;list-style:none;[itemBGColorTrans]",
    "%_label": "padding-left:[textSpacing];padding-right:[textSpacing];[fnt]  color:[txt];display:inline;white-space:nowrap;overflow:hidden;",
    "%_subMenu %_label": "[fntDrop]",
    "%_itemWrapper": "width:100%;height:100%;position:absolute;top:0;left:0;",
    "%_arrowWrapper": "z-index:1000;display:none;width:40px;height:100%;position:absolute;top:0;",
    "%_arrow": "position:absolute;width:100%;height:50%;overflow:hidden;",
    "%_arrow:after": "width:100%;height:100%;position:absolute;content:\" \";border-width:2000px;border-left-width:1000px;border-right-width:1000px;border-color:transparent;border-style:solid;",
    "%_arrowTop": "top:0;",
    "%_arrowBottom": "top:50%;",
    "%_arrowTop:after": "top:0;",
    "%_arrowBottom:after": "bottom:0;",
    "%[data-state~=\"subMenuOpenSide-right\"] %_arrowWrapper": "left:100%;",
    "%[data-state~=\"subMenuOpenSide-right\"] %_arrow": "left:0;",
    "%[data-state~=\"subMenuOpenSide-right\"] %_arrow:after": "left:0;",
    "%[data-state~=\"subMenuOpenSide-left\"] %_arrowWrapper": "right:100%;",
    "%[data-state~=\"subMenuOpenSide-left\"] %_arrow": "right:0;",
    "%[data-state~=\"subMenuOpenSide-left\"] %_arrow:after": "right:0;",
    "%_item%_hover %_arrowWrapper": "display:block;",
    "%_item%_hover:not(%_noLink) > %_itemWrapper": "background-color:[bgh];",
    "%_item%_hover:not(%_noLink) > %_itemWrapper > %_label": "color:[txth];",
    "%_item%_hover:not(%_noLink) %_arrow:after": "border-left-color:[bgh];border-right-color:[bgh];",
    "%_subMenu > %_item%_hover:not(%_noLink)": "background-color:[bgh];[itemBGColorTrans]",
    "%_subMenu > %_item%_hover:not(%_noLink) > %_label": "color:[txth];",
    "%_item%_selected > %_arrowWrapper": "display:block;",
    "%_item%_selectedContainer > %_arrowWrapper": "display:block;",
    "%_item%_selected > %_itemWrapper": "background-color:[bgs];",
    "%_item%_selectedContainer > %_itemWrapper": "background-color:[bgs];",
    "%_item%_selected > %_itemWrapper > %_label": "color:[txts];",
    "%_item%_selectedContainer > %_itemWrapper > %_label": "color:[txts];",
    "%_item%_selected > %_arrowWrapper > %_arrow:after": "border-left-color:[bgs];border-right-color:[bgs];",
    "%_item%_selectedContainer > %_arrowWrapper > %_arrow:after": "border-left-color:[bgs];border-right-color:[bgs];",
    "%[data-state~=\"items-align-left\"] %_item": "text-align:left;",
    "%[data-state~=\"items-align-center\"] %_item": "text-align:center;",
    "%[data-state~=\"items-align-right\"] %_item": "text-align:right;",
    "%[data-state~=\"subItems-align-left\"] %_subMenu > %_item": "text-align:left;padding-left:[textSpacing];padding-right:[subItemAlterAlignPad];",
    "%[data-state~=\"subItems-align-center\"] %_subMenu > %_item": "text-align:center;padding-left:[textSpacing];",
    "%[data-state~=\"subItems-align-right\"] %_subMenu > %_item": "text-align:right;padding-left:[subItemAlterAlignPad];padding-right:[textSpacing];",
    "%_item:first-child": "[rdTop]",
    "%_item:last-child": "[rdBottom]  border-bottom:1px solid transparent;",
    "%_subMenu > %_item:first-child": "[dropDownRdTop]",
    "%_subMenu > %_item:last-child": "[dropDownRdBottom]  border-bottom:1px solid transparent;",
    "%_subMenu": "z-index:999;min-width:100%;opacity:0;display:none;position:absolute;[subMenuOpacityTrans]  background-color:[bgDrop];[dropdownBR]  [shd]",
    "%_item%_hover %_subMenu": "opacity:1;[subMenuOpacityTrans]  display:block;",
    "%[data-state~=\"subMenuOpenSide-right\"] %_subMenu": "left:100%;float:left;margin-left:[dropdownMargin];",
    "%[data-state~=\"subMenuOpenSide-left\"] %_subMenu": "right:100%;float:right;margin-right:[dropdownMargin];",
    "%[data-open-direction~=\"subMenuOpenDir-down\"] %_subMenu": "top:0;",
    "%[data-open-direction~=\"subMenuOpenDir-up\"] %_subMenu": "bottom:0;",
    "%_subMenu:before": "content:\" \";display:block;width:[dropdownMargin];height:100%;position:absolute;top:0;",
    "%[data-state~=\"subMenuOpenSide-right\"] %_subMenu:before": "left:0;margin-left:calc(-1 * [dropdownMargin]);",
    "%[data-state~=\"subMenuOpenSide-left\"] %_subMenu:before": "right:0;margin-right:calc(-1 * [dropdownMargin]);"
  }
}
 skins['wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuRibbonsSkin'] = {
  "react": [
    [
      "ul",
      "menuContainer",
      [],
      {},
      [
        "li",
        "menuItem",
        [
          "_item"
        ],
        {},
        [
          "div",
          null,
          [
            "_ribbonWrapper"
          ],
          {},
          [
            "span",
            null,
            [
              "_ribbon",
              "_ribTop"
            ],
            {}
          ],
          [
            "span",
            null,
            [
              "_ribbon",
              "_ribBottom"
            ],
            {}
          ],
          [
            "span",
            null,
            [
              "_ribFold"
            ],
            {}
          ]
        ],
        [
          "div",
          null,
          [
            "_itemWrapper"
          ],
          {},
          [
            "a",
            null,
            [],
            {}
          ],
          [
            "ul",
            null,
            [
              "_subMenu"
            ],
            {}
          ]
        ]
      ],
      [
        "li",
        "subMenuItem",
        [
          "_item"
        ],
        {},
        [
          "a",
          null,
          [],
          {}
        ],
        [
          "ul",
          null,
          [],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "menuContainer": {
      "skin": "ComboBoxInputSkin",
      "dontMergeParams": true
    }
  },
  "params": {
    "shd": "BOX_SHADOW",
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "itemBGColorTrans": "TRANSITION",
    "txth": "TEXT_COLOR",
    "bgh": "BORDER_COLOR_ALPHA",
    "bgs": "BORDER_COLOR_ALPHA",
    "txts": "TEXT_COLOR",
    "rdTop": "BORDER_RADIUS",
    "rdBottom": "BORDER_RADIUS",
    "textSpacing": "PADDING_SIZE",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "subMenuOpacityTrans": "TRANSITION",
    "SKINS_bgSubmenu": "BG_COLOR_ALPHA",
    "SKINS_submenuBR": "BORDER_RADIUS",
    "SKINS_fntSubmenu": "FONT",
    "dropDownRdTop": "BORDER_RADIUS",
    "dropDownRdBottom": "BORDER_RADIUS",
    "SKINS_submenuMargin": "MARGIN_SIZE",
    "SKINS_foldh": "COLOR_ALPHA",
    "SKINS_folds": "COLOR_ALPHA",
    "subItemAlterAlignPad": "SIZE"
  },
  "paramsDefaults": {
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "rd": "0",
    "bg": "color_11",
    "itemBGColorTrans": "background-color 0.4s ease 0s",
    "txth": "color_13",
    "bgh": "color_15",
    "bgs": "color_15",
    "txts": "color_13",
    "rdTop": [
      "rd"
    ],
    "rdBottom": [
      "rd"
    ],
    "textSpacing": "10px",
    "fnt": "font_1",
    "txt": "color_15",
    "subMenuOpacityTrans": "all 0.4s ease 0s",
    "SKINS_bgSubmenu": "color_11",
    "SKINS_submenuBR": "0",
    "SKINS_fntSubmenu": "font_1",
    "dropDownRdTop": [
      "SKINS_submenuBR"
    ],
    "dropDownRdBottom": [
      "SKINS_submenuBR"
    ],
    "SKINS_submenuMargin": "1px",
    "SKINS_foldh": [
      "bgh"
    ],
    "SKINS_folds": [
      "bgs"
    ],
    "subItemAlterAlignPad": [
      "textSpacing"
    ]
  },
  "paramsMutators": {
    "SKINS_foldh": {
      "type": "brightness",
      "value": 0.7,
      "param": "bgh"
    },
    "SKINS_folds": {
      "type": "brightness",
      "value": 0.7,
      "param": "bgs"
    },
    "subItemAlterAlignPad": {
      "type": "increase",
      "value": 0,
      "param": "textSpacing"
    }
  },
  "css": {
    "% > ul": "display:table;width:100%;box-sizing:border-box;",
    "% li": "display:table;width:100%;",
    "% a span": "pointer-events:none;",
    "%_noLink": "cursor:default !important;",
    "%_counter": "margin:0 10px;opacity:0.6;",
    "%menuContainer": "padding:0;margin:0;position:relative;[shd]  [rd]",
    "%menuContainer %_emptySubMenu": "display:none !important;",
    "%_item": "background-color:[bg];margin:0;position:relative;display:block;cursor:pointer;list-style:none;[itemBGColorTrans]",
    "%_item%_hover %_ribbonWrapper": "display:block;",
    "%_item%_hover:not(%_noLink) > %_itemWrapper > %_label": "color:[txth];",
    "%_item%_hover:not(%_noLink) %_ribTop:after": "border-top-color:[bgh];",
    "%_item%_hover:not(%_noLink) %_ribBottom:after": "border-bottom-color:[bgh];",
    "%_item%_selectedContainer > %_ribbonWrapper,%_item%_selected > %_ribbonWrapper": "display:block;",
    "%_item%_selectedContainer > %_ribbonWrapper > %_ribTop:after,%_item%_selected > %_ribbonWrapper > %_ribTop:after": "border-top-color:[bgs];",
    "%_item%_selectedContainer > %_ribbonWrapper > %_ribBottom:after,%_item%_selected > %_ribbonWrapper > %_ribBottom:after": "border-bottom-color:[bgs];",
    "%_item%_selectedContainer > %_itemWrapper > %_label,%_item%_selected > %_itemWrapper > %_label": "color:[txts];",
    "%_item%_selected > %_label": "color:[txts] !important;",
    "%_item:first-child": "[rdTop]",
    "%_item:last-child": "[rdBottom]",
    "%_label": "padding-left:[textSpacing];padding-right:[textSpacing];[fnt]  color:[txt];display:inline;white-space:nowrap;overflow:hidden;",
    "%_subMenu": "z-index:999;min-width:100%;opacity:0;display:none;position:absolute;[subMenuOpacityTrans]  background-color:[SKINS_bgSubmenu];border:solid 0 transparent;[SKINS_submenuBR]  [shd]",
    "%_subMenu %_label": "[SKINS_fntSubmenu]",
    "%_subMenu > %_item%_hover:not(%_noLink)": "background-color:[bgh];[itemBGColorTrans]",
    "%_subMenu > %_item%_hover:not(%_noLink) %_label": "color:[txth];",
    "%_subMenu > %_item%_selected": "background-color:[bgs];",
    "%_subMenu %_item": "background-color:[SKINS_bgSubmenu];",
    "%_subMenu %_item:first-child": "[dropDownRdTop]",
    "%_subMenu %_item:first-child:last-child": "[SKINS_submenuBR]",
    "%_subMenu %_item:last-child": "[dropDownRdBottom]",
    "%_subMenu:before": "content:\" \";display:block;width:[SKINS_submenuMargin];height:100%;position:absolute;top:0;",
    "%_itemWrapper": "width:100%;height:100%;position:absolute;top:0;left:0;",
    "%_itemWrapper%_hover > %_subMenu": "opacity:1;[subMenuOpacityTrans]    display:block;",
    "%_ribbonWrapper": "display:none;width:100%;position:absolute;top:10px;bottom:10px;left:0;",
    "% span": "position:absolute;width:calc(100% + 10px);height:50%;overflow:hidden;",
    "% span:after": "width:100%;height:100%;position:absolute;content:\"\";border:solid 1000px transparent;",
    "%[data-state~=\"subMenuOpenSide-left\"] %_ribTop,%[data-state~=\"subMenuOpenSide-right\"] %_ribTop": "top:0;",
    "%[data-state~=\"subMenuOpenSide-left\"] %_ribTop:after,%[data-state~=\"subMenuOpenSide-right\"] %_ribTop:after": "top:0;",
    "%[data-state~=\"subMenuOpenSide-left\"] %_ribBottom,%[data-state~=\"subMenuOpenSide-right\"] %_ribBottom": "bottom:0;",
    "%[data-state~=\"subMenuOpenSide-left\"] %_ribBottom:after,%[data-state~=\"subMenuOpenSide-right\"] %_ribBottom:after": "bottom:0;",
    "%[data-state~=\"subMenuOpenSide-left\"] %_ribFold,%[data-state~=\"subMenuOpenSide-right\"] %_ribFold": "position:absolute;bottom:-9px;right:-10px;width:0;height:0;border-style:solid;border-color:[SKINS_foldh] transparent transparent;",
    "%[data-state~=\"subMenuOpenSide-right\"] %_ribTop,%[data-state~=\"subMenuOpenSide-right\"] %_ribBottom": "left:-10px;",
    "%[data-state~=\"subMenuOpenSide-right\"] %_ribTop:after,%[data-state~=\"subMenuOpenSide-right\"] %_ribBottom:after": "right:7px;border-top-color:[bgh];border-right-width:500px;",
    "%[data-state~=\"subMenuOpenSide-right\"] %_ribFold": "border-width:0 10px 10px 0;",
    "%[data-state~=\"subMenuOpenSide-right\"] %_item%_selected > %_ribbonWrapper > %_ribFold,%[data-state~=\"subMenuOpenSide-right\"] %_item%_selectedContainer > %_ribbonWrapper > %_ribFold": "border-color:transparent [SKINS_folds] transparent transparent;",
    "%[data-state~=\"subMenuOpenSide-right\"] %_subMenu": "left:100%;float:left;margin-left:[SKINS_submenuMargin];",
    "%[data-state~=\"subMenuOpenSide-right\"] %_subMenu:before": "left:0;margin-left:calc(-1 * [SKINS_submenuMargin]);",
    "%[data-state~=\"subMenuOpenSide-left\"] %_ribTop,%[data-state~=\"subMenuOpenSide-left\"] %_ribBottom": "right:-10px;left:7px;border-top-color:[bgh];border-left-width:500px;",
    "%[data-state~=\"subMenuOpenSide-left\"] %_ribFold": "border-width:10px 10px 0 0;",
    "%[data-state~=\"subMenuOpenSide-left\"] %_item%_selected > %_ribbonWrapper > %_ribFold,%[data-state~=\"subMenuOpenSide-left\"] %_item%_selectedContainer > %_ribbonWrapper > %_ribFold": "border-color:[SKINS_folds] transparent transparent;",
    "%[data-state~=\"subMenuOpenSide-left\"] %_subMenu": "right:100%;float:right;margin-right:[SKINS_submenuMargin];",
    "%[data-state~=\"subMenuOpenSide-left\"] %_subMenu:before": "right:0;margin-right:calc(-1 * [SKINS_submenuMargin]);",
    "%[data-state~=\"items-align-left\"] %_item": "text-align:left;",
    "%[data-state~=\"items-align-center\"] %_item": "text-align:center;",
    "%[data-state~=\"items-align-right\"] %_item": "text-align:right;",
    "%[data-state~=\"subItems-align-left\"] %_subMenu > %_item": "text-align:left;padding-left:[textSpacing];padding-right:[subItemAlterAlignPad];",
    "%[data-state~=\"subItems-align-center\"] %_subMenu > %_item": "text-align:center;",
    "%[data-state~=\"subItems-align-right\"] %_subMenu > %_item": "text-align:right;padding-left:[subItemAlterAlignPad];padding-right:[textSpacing];",
    "%[data-open-direction~=\"subMenuOpenDir-down\"] %_subMenu": "top:0;",
    "%[data-open-direction~=\"subMenuOpenDir-up\"] %_subMenu": "bottom:0;"
  }
}
 skins['wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSeparatedButtonFixedWidthSkin'] = {
  "react": [
    [
      "ul",
      "menuContainer",
      [],
      {},
      [
        "li",
        "menuItem",
        [
          "_item"
        ],
        {},
        [
          "div",
          null,
          [
            "_itemContentWrapper"
          ],
          {},
          [
            "a",
            null,
            [],
            {}
          ],
          [
            "ul",
            null,
            [
              "_subMenu"
            ],
            {}
          ]
        ],
        [
          "div",
          null,
          [
            "_separator"
          ],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "menuContainer": {
      "skin": "ComboBoxInputSkin",
      "dontMergeParams": true
    },
    "separatorNotIncludedInLineHeight": true,
    "borderNotIncludedInLineHeight": true
  },
  "params": {
    "subMenuOpacityTrans": "TRANSITION",
    "SKINS_submenuMargin": "MARGIN_SIZE",
    "SKINS_fntSubmenu": "FONT",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "SKINS_bgSubmenu": "BG_COLOR_ALPHA",
    "SKINS_submenuBR": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "rd": "BORDER_RADIUS",
    "itemBGColorTrans": "TRANSITION",
    "textSpacing": "PADDING_SIZE",
    "bg": "BG_COLOR_ALPHA",
    "bgs": "BG_COLOR_ALPHA",
    "txts": "TEXT_COLOR",
    "bgh": "BG_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "separatorHeight": "HEIGHT_SIZE",
    "subItemAlterAlignPad": "SIZE",
    "dropdownMarginReal": "SIZE",
    "rdTop": "BORDER_RADIUS",
    "rdBottom": "BORDER_RADIUS",
    "dropDownRdTop": "BORDER_RADIUS",
    "dropDownRdBottom": "BORDER_RADIUS"
  },
  "paramsDefaults": {
    "subMenuOpacityTrans": "all 0.6s ease 0s",
    "SKINS_submenuMargin": "8px",
    "SKINS_fntSubmenu": "font_1",
    "brw": "1px",
    "brd": "color_15",
    "SKINS_bgSubmenu": "color_11",
    "SKINS_submenuBR": "0",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "rd": "0",
    "itemBGColorTrans": "background-color 0.4s ease 0s",
    "textSpacing": "10px",
    "bg": "color_11",
    "bgs": "color_15",
    "txts": "color_13",
    "bgh": "color_15",
    "txth": "color_13",
    "fnt": "font_1",
    "txt": "color_15",
    "separatorHeight": "8",
    "subItemAlterAlignPad": [
      "textSpacing"
    ],
    "rdTop": [
      "rd"
    ],
    "rdBottom": [
      "rd"
    ],
    "dropDownRdTop": [
      "SKINS_submenuBR"
    ],
    "dropDownRdBottom": [
      "SKINS_submenuBR"
    ],
    "dropdownMarginReal": [
      "SKINS_submenuMargin",
      "brw",
      "brw"
    ]
  },
  "paramsMutators": {
    "subItemAlterAlignPad": {
      "type": "increase",
      "value": 0,
      "param": "textSpacing"
    }
  },
  "css": {
    "% > ul": "display:table;width:100%;box-sizing:border-box;",
    "% li": "display:table;width:100%;",
    "% a span": "pointer-events:none;",
    "%_noLink": "cursor:default !important;",
    "%_counter": "margin:0 10px;opacity:0.6;",
    "%": "pointer-events:none;",
    "%menuContainer": "pointer-events:auto;padding:0;margin:0;width:100%;position:relative;",
    "%menuContainer %_emptySubMenu": "display:none;",
    "%_subMenu": "min-width:100%;z-index:999;background-color:transparent;opacity:0;display:none;position:absolute;[subMenuOpacityTrans]",
    "%_subMenu:before": "content:\" \";display:block;width:[SKINS_submenuMargin];height:100%;position:absolute;top:0;",
    "%_subMenu %_label": "[SKINS_fntSubmenu]",
    "%_subMenu %_itemContentWrapper": "border:solid [brw] [brd];background-color:[SKINS_bgSubmenu];[SKINS_submenuBR]    [shd]",
    "%_itemContentWrapper": "display:block;border:solid [brw] [brd];[shd]  [rd]  [itemBGColorTrans]  cursor:pointer;padding-left:[textSpacing];padding-right:[textSpacing];background-color:[bg];",
    "%_item": "width:100%;background-color:transparent;margin:0;position:relative;list-style:none;",
    "%_item:last-child %_separator": "display:none;",
    "%_item%_selected > %_itemContentWrapper,%_item%_selectedContainer > %_itemContentWrapper": "background-color:[bgs];[itemBGColorTrans]",
    "%_item%_selected > %_itemContentWrapper > %_label,%_item%_selectedContainer > %_itemContentWrapper > %_label": "color:[txts];",
    "%_item%_hover %_subMenu": "background-color:transparent;opacity:1;[subMenuOpacityTrans]    display:block;",
    "%_item%_hover > %_itemContentWrapper:not(%_noLink)": "background-color:[bgh];[itemBGColorTrans]",
    "%_item%_hover > %_itemContentWrapper:not(%_noLink) > %_label": "color:[txth];position:relative;",
    "%_item%_hover > %_itemContentWrapper:before": "content:\" \";position:absolute;left:0;right:0;height:100%;top:0;",
    "%_label": "[fnt]  color:[txt];display:inline;white-space:nowrap;overflow:hidden;",
    "%[data-state~=\"items-align-left\"]": "text-align:left;",
    "%[data-state~=\"items-align-left\"] %_item": "text-align:left;",
    "%[data-state~=\"items-align-center\"]": "text-align:center;",
    "%[data-state~=\"items-align-center\"] %_item": "text-align:center;",
    "%[data-state~=\"items-align-right\"]": "text-align:right;",
    "%[data-state~=\"items-align-right\"] %_item": "text-align:right;",
    "%[data-state~=\"subItems-align-left\"] %_subMenu %_item": "text-align:left;",
    "%[data-state~=\"subItems-align-left\"] %_subMenu %_itemContentWrapper": "padding-left:[textSpacing];padding-right:[subItemAlterAlignPad];",
    "%[data-state~=\"subItems-align-center\"] %_subMenu %_item": "text-align:center;",
    "%[data-state~=\"subItems-align-center\"] %_subMenu %_itemContentWrapper": "padding-left:[textSpacing];padding-right:[textSpacing];",
    "%[data-state~=\"subItems-align-right\"] %_subMenu %_item": "text-align:right;",
    "%[data-state~=\"subItems-align-right\"] %_subMenu %_itemContentWrapper": "padding-left:[subItemAlterAlignPad];padding-right:[textSpacing];",
    "%[data-state~=\"subMenuOpenSide-right\"] %_subMenu": "left:calc(100% - 1px);float:left;margin-left:[SKINS_submenuMargin];",
    "%[data-state~=\"subMenuOpenSide-right\"] %_subMenu:before": "left:0;margin-left:calc(-1 * [SKINS_submenuMargin]);",
    "%[data-state~=\"subMenuOpenSide-left\"] %_subMenu": "right:calc(100% - 1px);float:right;margin-right:[SKINS_submenuMargin];",
    "%[data-state~=\"subMenuOpenSide-left\"] %_subMenu:before": "right:0;margin-right:calc(-1 * [SKINS_submenuMargin]);",
    "%[data-open-direction~=\"subMenuOpenDir-down\"] %_subMenu": "top:0;",
    "%[data-open-direction~=\"subMenuOpenDir-up\"] %_subMenu": "bottom:0;",
    "%_separator": "width:100%;height:calc([separatorHeight] - 2px);min-height:0;background-color:transparent;"
  }
}
 skins['wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSeparatedButtonSkin'] = {
  "react": [
    [
      "ul",
      "menuContainer",
      [],
      {},
      [
        "li",
        "menuItem",
        [
          "_item"
        ],
        {},
        [
          "div",
          null,
          [
            "_itemContentWrapper"
          ],
          {},
          [
            "a",
            null,
            [],
            {}
          ],
          [
            "ul",
            null,
            [
              "_subMenu"
            ],
            {}
          ]
        ],
        [
          "div",
          null,
          [
            "_separator"
          ],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "menuContainer": {
      "skin": "ComboBoxInputSkin",
      "dontMergeParams": true
    },
    "separatorNotIncludedInLineHeight": true,
    "borderNotIncludedInLineHeight": true
  },
  "params": {
    "subMenuOpacityTrans": "TRANSITION",
    "SKINS_fntSubmenu": "FONT",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "SKINS_bgSubmenu": "BG_COLOR_ALPHA",
    "SKINS_submenuBR": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "SKINS_submenuMargin": "MARGIN_SIZE",
    "rd": "BORDER_RADIUS",
    "itemBGColorTrans": "TRANSITION",
    "textSpacing": "PADDING_SIZE",
    "bg": "BG_COLOR_ALPHA",
    "bgs": "BG_COLOR_ALPHA",
    "txts": "TEXT_COLOR",
    "bgh": "BG_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "separatorHeight": "HEIGHT_SIZE",
    "subItemAlterAlignPad": "SIZE",
    "dropdownMarginReal": "SIZE",
    "rdTop": "BORDER_RADIUS",
    "rdBottom": "BORDER_RADIUS",
    "dropDownRdTop": "BORDER_RADIUS",
    "dropDownRdBottom": "BORDER_RADIUS"
  },
  "paramsDefaults": {
    "subMenuOpacityTrans": "all 0.6s ease 0s",
    "SKINS_fntSubmenu": "font_1",
    "brw": "1px",
    "brd": "color_15",
    "SKINS_bgSubmenu": "color_11",
    "SKINS_submenuBR": "0",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "SKINS_submenuMargin": "8px",
    "rd": "0",
    "itemBGColorTrans": "background-color 0.4s ease 0s",
    "textSpacing": "10px",
    "bg": "color_11",
    "bgs": "color_15",
    "txts": "color_13",
    "bgh": "color_15",
    "txth": "color_13",
    "fnt": "font_1",
    "txt": "color_15",
    "separatorHeight": "8",
    "subItemAlterAlignPad": [
      "textSpacing"
    ],
    "rdTop": [
      "rd"
    ],
    "rdBottom": [
      "rd"
    ],
    "dropDownRdTop": [
      "SKINS_submenuBR"
    ],
    "dropDownRdBottom": [
      "SKINS_submenuBR"
    ],
    "dropdownMarginReal": [
      "SKINS_submenuMargin",
      "brw",
      "brw"
    ]
  },
  "paramsMutators": {
    "subItemAlterAlignPad": {
      "type": "increase",
      "value": 0,
      "param": "textSpacing"
    }
  },
  "css": {
    "% > ul": "display:table;width:100%;box-sizing:border-box;",
    "% li": "display:table;width:100%;",
    "% a span": "pointer-events:none;",
    "%_noLink": "cursor:default !important;",
    "%_counter": "margin:0 10px;opacity:0.6;",
    "%": "pointer-events:none;",
    "%menuContainer": "pointer-events:auto;padding:0;margin:0;position:relative;display:inline-block;",
    "%menuContainer %_emptySubMenu": "display:none;",
    "%_subMenu": "min-width:100%;z-index:999;background-color:transparent;opacity:0;display:none;position:absolute;[subMenuOpacityTrans]",
    "%_subMenu %_label": "[SKINS_fntSubmenu]",
    "%_subMenu %_itemContentWrapper": "border:solid [brw] [brd];background-color:[SKINS_bgSubmenu];[SKINS_submenuBR]    [shd]",
    "%_subMenu:before": "content:\" \";display:block;width:[SKINS_submenuMargin];height:100%;position:absolute;top:0;",
    "%_itemContentWrapper": "display:inline-block;border:solid [brw] [brd];[shd]  [rd]  [itemBGColorTrans]  cursor:pointer;padding-left:[textSpacing];padding-right:[textSpacing];background-color:[bg];",
    "%_item": "width:100%;background-color:transparent;margin:0;position:relative;float:left;clear:left;list-style:none;",
    "%_item:last-child %_separator": "display:none;",
    "%_item%_selected > %_itemContentWrapper,%_item%_selectedContainer > %_itemContentWrapper": "background-color:[bgs];[itemBGColorTrans]",
    "%_item%_selected > %_itemContentWrapper > %_label,%_item%_selectedContainer > %_itemContentWrapper > %_label": "color:[txts];",
    "%_item%_hover %_subMenu": "background-color:transparent;opacity:1;[subMenuOpacityTrans]    display:block;",
    "%_item%_hover > %_itemContentWrapper:not(%_noLink)": "background-color:[bgh];[itemBGColorTrans]",
    "%_item%_hover > %_itemContentWrapper:not(%_noLink) > %_label": "color:[txth];",
    "%_item%_hover > %_itemContentWrapper:before": "content:\" \";position:absolute;left:0;right:0;height:100%;top:0;",
    "%_label": "[fnt]  color:[txt];display:inline;white-space:nowrap;overflow:hidden;",
    "%[data-state~=\"items-align-left\"]": "text-align:left;",
    "%[data-state~=\"items-align-left\"] %_item": "text-align:left;",
    "%[data-state~=\"items-align-center\"]": "text-align:center;",
    "%[data-state~=\"items-align-center\"] %_item": "text-align:center;",
    "%[data-state~=\"items-align-right\"]": "text-align:right;",
    "%[data-state~=\"items-align-right\"] %_item": "text-align:right;",
    "%[data-state~=\"subItems-align-left\"] %_subMenu %_item": "text-align:left;",
    "%[data-state~=\"subItems-align-left\"] %_subMenu %_itemContentWrapper": "padding-left:[textSpacing];padding-right:[subItemAlterAlignPad];",
    "%[data-state~=\"subItems-align-center\"] %_subMenu %_item": "text-align:center;",
    "%[data-state~=\"subItems-align-center\"] %_subMenu %_itemContentWrapper": "padding-left:[textSpacing];padding-right:[textSpacing];",
    "%[data-state~=\"subItems-align-right\"] %_subMenu %_item": "text-align:right;",
    "%[data-state~=\"subItems-align-right\"] %_subMenu %_itemContentWrapper": "padding-left:[subItemAlterAlignPad];padding-right:[textSpacing];",
    "%[data-state~=\"subMenuOpenSide-right\"] %_subMenu": "left:100%;float:left;margin-left:[SKINS_submenuMargin];",
    "%[data-state~=\"subMenuOpenSide-right\"] %_subMenu:before": "left:0;margin-left:calc(-1 * [SKINS_submenuMargin]);",
    "%[data-state~=\"subMenuOpenSide-left\"] %_subMenu": "right:100%;float:right;margin-right:[SKINS_submenuMargin];",
    "%[data-state~=\"subMenuOpenSide-left\"] %_subMenu:before": "right:0;margin-right:calc(-1 * [SKINS_submenuMargin]);",
    "%[data-open-direction~=\"subMenuOpenDir-down\"] %_subMenu": "top:0;",
    "%[data-open-direction~=\"subMenuOpenDir-up\"] %_subMenu": "bottom:0;",
    "%_separator": "width:100%;height:calc([separatorHeight] - 2px);min-height:0;background-color:transparent;"
  }
}
 skins['wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSolidColorSkin'] = {
  "react": [
    [
      "ul",
      "menuContainer",
      [],
      {},
      [
        "li",
        "menuItem",
        [
          "_itemHoverArea",
          "_itemWrapper"
        ],
        {},
        [
          "div",
          null,
          [
            "_item"
          ],
          {},
          [
            "a",
            null,
            [],
            {}
          ],
          [
            "ul",
            null,
            [
              "_subMenu"
            ],
            {}
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "menuContainer": {
      "skin": "ComboBoxInputSkin",
      "dontMergeParams": true
    },
    "separatorNotIncludedInLineHeight": true
  },
  "params": {
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "shd": "BOX_SHADOW",
    "rd": "BORDER_RADIUS",
    "sepw": "BORDER_BOTTOM_SIZE",
    "sep": "COLOR_ALPHA",
    "itemBGColorTrans": "TRANSITION",
    "bgh": "BG_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "bgs": "BG_COLOR_ALPHA",
    "txts": "TEXT_COLOR",
    "textSpacing": "PADDING_SIZE",
    "bg": "BG_COLOR_ALPHA",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "subMenuOpacityTrans": "TRANSITION",
    "SKINS_submenuBR": "BORDER_RADIUS",
    "SKINS_bgSubmenu": "BG_COLOR_ALPHA",
    "SKINS_fntSubmenu": "FONT",
    "SKINS_submenuMargin": "MARGIN_SIZE",
    "subItemAlterAlignPad": "SIZE",
    "dropdownMarginReal": "SIZE",
    "separatorHeight": "BORDER_BOTTOM_SIZE"
  },
  "paramsDefaults": {
    "brw": "1px",
    "brd": "color_15",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "rd": "0",
    "sepw": "1px",
    "sep": "color_15",
    "itemBGColorTrans": "background-color 0.4s ease 0s",
    "bgh": "color_15",
    "txth": "color_13",
    "bgs": "color_15",
    "txts": "color_13",
    "textSpacing": "10px",
    "bg": "color_11",
    "fnt": "font_1",
    "txt": "color_15",
    "subMenuOpacityTrans": "all 0.4s ease 0s",
    "SKINS_submenuBR": "0",
    "SKINS_bgSubmenu": "color_11",
    "SKINS_fntSubmenu": "font_1",
    "SKINS_submenuMargin": "0",
    "subItemAlterAlignPad": [
      "textSpacing"
    ],
    "separatorHeight": [
      "sepw"
    ],
    "dropdownMarginReal": [
      "SKINS_submenuMargin",
      "brw"
    ]
  },
  "paramsMutators": {
    "subItemAlterAlignPad": {
      "type": "increase",
      "value": 0,
      "param": "textSpacing"
    }
  },
  "css": {
    "% > ul": "display:table;width:100%;box-sizing:border-box;",
    "% li": "display:table;width:100%;",
    "% a span": "pointer-events:none;",
    "%_noLink": "cursor:default !important;",
    "%_counter": "margin:0 10px;opacity:0.6;",
    "%menuContainer": "padding:0;margin:0;border:solid [brw] [brd];position:relative;[shd]  [rd]",
    "%menuContainer %_emptySubMenu": "display:none !important;",
    "%_itemHoverArea": "box-sizing:content-box;border-bottom:solid [sepw] [sep];",
    "%_itemHoverArea:first-child > %_item": "[rd]    border-bottom-left-radius:0;border-bottom-right-radius:0;",
    "%_itemHoverArea:last-child": "border-bottom:0 solid transparent;",
    "%_itemHoverArea:last-child > %_item": "[rd]      border-top-left-radius:0;border-top-right-radius:0;border-bottom:0 solid transparent;",
    "%_itemHoverArea%_hover > %_item,%_itemHoverArea%_selected > %_item,%_itemHoverArea%_selectedContainer > %_item": "[itemBGColorTrans]",
    "%_itemHoverArea%_hover %_subMenu": "opacity:1;display:block;",
    "%_itemHoverArea%_hover:not(%_noLink) > %_item": "background-color:[bgh];",
    "%_itemHoverArea%_hover:not(%_noLink) > %_item > %_label": "color:[txth];",
    "%_itemHoverArea%_selected > %_item,%_itemHoverArea%_selectedContainer > %_item": "background-color:[bgs];",
    "%_itemHoverArea%_selected > %_item > %_label,%_itemHoverArea%_selectedContainer > %_item > %_label": "color:[txts];",
    "%_item": "height:100%;padding-left:[textSpacing];padding-right:[textSpacing];[itemBGColorTrans]  margin:0;position:relative;cursor:pointer;list-style:none;background-color:[bg];",
    "%_label": "[fnt]  color:[txt];display:inline;white-space:nowrap;overflow:hidden;",
    "%_subMenu": "z-index:999;min-width:100%;display:none;opacity:0;[subMenuOpacityTrans]  position:absolute;border:solid [brw] [brd];[SKINS_submenuBR]  [shd]",
    "%_subMenu %_item": "background-color:[SKINS_bgSubmenu];",
    "%_subMenu %_label": "[SKINS_fntSubmenu]",
    "%_subMenu > %_itemHoverArea:first-child > %_item,%_subMenu > %_itemHoverArea:first-child:last-child > %_item": "[SKINS_submenuBR]",
    "%_subMenu > %_itemHoverArea:first-child > %_item": "border-bottom-left-radius:0;border-bottom-right-radius:0;",
    "%_subMenu > %_itemHoverArea:last-child": "border-bottom:0 solid transparent;",
    "%_subMenu > %_itemHoverArea:last-child > %_item": "[SKINS_submenuBR]      border-top-left-radius:0;border-top-right-radius:0;border-bottom:0 solid transparent;",
    "%_subMenu:before": "background-color:#fff;opacity:0;z-index:999;content:\" \";display:block;width:calc([SKINS_submenuMargin] + [brw]);height:100%;position:absolute;top:0;",
    "%[data-state~=\"items-align-left\"] %_item": "text-align:left;",
    "%[data-state~=\"items-align-center\"] %_item": "text-align:center;",
    "%[data-state~=\"items-align-right\"] %_item": "text-align:right;",
    "%[data-state~=\"subItems-align-left\"] %_subMenu > %_item": "text-align:left;padding-left:[textSpacing];padding-right:[subItemAlterAlignPad];",
    "%[data-state~=\"subItems-align-center\"] %_subMenu > %_item": "text-align:center;padding-left:[textSpacing];padding-right:[textSpacing];",
    "%[data-state~=\"subItems-align-right\"] %_subMenu > %_item": "text-align:right;padding-left:[subItemAlterAlignPad];padding-right:[textSpacing];",
    "%[data-state~=\"subMenuOpenSide-right\"] %_subMenu": "left:100%;float:left;margin-left:[SKINS_submenuMargin];",
    "%[data-state~=\"subMenuOpenSide-right\"] %_subMenu:before": "right:100%;",
    "%[data-state~=\"subMenuOpenSide-left\"] %_subMenu": "right:100%;float:right;margin-right:[SKINS_submenuMargin];",
    "%[data-state~=\"subMenuOpenSide-left\"] %_subMenu:before": "left:100%;",
    "%[data-open-direction~=\"subMenuOpenDir-down\"] %_subMenu": "top:calc(-1 * [brw]);",
    "%[data-open-direction~=\"subMenuOpenDir-up\"] %_subMenu": "bottom:calc(-1 * [brw]);"
  }
}
 skins['wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuTextSkin'] = {
  "react": [
    [
      "ul",
      "menuContainer",
      [],
      {},
      [
        "li",
        "menuItem",
        [
          "_item",
          "_itemWrapper"
        ],
        {},
        [
          "a",
          null,
          [],
          {}
        ],
        [
          "ul",
          null,
          [
            "_subMenu"
          ],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "menuContainer": {
      "skin": "ComboBoxInputSkin",
      "dontMergeParams": true
    }
  },
  "params": {
    "textSpacing": "PADDING_SIZE",
    "txth": "TEXT_COLOR",
    "subMenuOpacityTrans": "TRANSITION",
    "txts": "TEXT_COLOR",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "SKINS_bgSubmenu": "BG_COLOR_ALPHA",
    "shd": "BOX_SHADOW",
    "SKINS_fntSubmenu": "FONT",
    "SKINS_submenuMargin": "MARGIN_SIZE",
    "subItemAlterAlignPad": "SIZE"
  },
  "paramsDefaults": {
    "textSpacing": "10px",
    "txth": "color_13",
    "subMenuOpacityTrans": "all 0.4s ease 0s",
    "txts": "color_13",
    "fnt": "font_1",
    "txt": "color_15",
    "SKINS_bgSubmenu": "color_11",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "SKINS_fntSubmenu": "font_1",
    "SKINS_submenuMargin": "1px",
    "subItemAlterAlignPad": [
      "textSpacing"
    ]
  },
  "paramsMutators": {
    "subItemAlterAlignPad": {
      "type": "increase",
      "value": 0,
      "param": "textSpacing"
    }
  },
  "css": {
    "% > ul": "display:table;width:100%;box-sizing:border-box;",
    "% li": "display:table;width:100%;width:calc(100% - (2 * [textSpacing]));",
    "% a span": "pointer-events:none;",
    "%_noLink": "cursor:default !important;",
    "%_counter": "margin:0 10px;opacity:0.6;",
    "%menuContainer": "padding:0;margin:0;position:relative;",
    "%menuContainer %_emptySubMenu": "display:none !important;",
    "%_item": "padding-left:[textSpacing];padding-right:[textSpacing];margin:0;position:relative;display:block;cursor:pointer;list-style:none;",
    "%_item:last-child": "border-bottom:0;",
    "%_item%_hover:not(%_noLink) > %_label": "color:[txth];",
    "%_item%_hover > %_subMenu": "opacity:1;[subMenuOpacityTrans]    display:block;",
    "%_item%_selected > %_label,%_item%_selectedContainer > %_label": "color:[txts];",
    "%_label": "[fnt]  color:[txt];display:inline;white-space:nowrap;overflow:hidden;",
    "%_subMenu": "z-index:999;min-width:100%;opacity:0;display:none;position:absolute;[subMenuOpacityTrans]  background-color:[SKINS_bgSubmenu];[shd]",
    "%_subMenu %_label": "[SKINS_fntSubmenu]",
    "%_subMenu:before": "content:\" \";display:block;width:[SKINS_submenuMargin];height:100%;position:absolute;top:0;",
    "%[data-state~=\"items-align-left\"] %_item": "text-align:left;",
    "%[data-state~=\"items-align-center\"] %_item": "text-align:center;",
    "%[data-state~=\"items-align-right\"] %_item": "text-align:right;",
    "%[data-state~=\"subItems-align-left\"] %_subMenu > %_item": "text-align:left;padding-left:[textSpacing];padding-right:[subItemAlterAlignPad];",
    "%[data-state~=\"subItems-align-center\"] %_subMenu > %_item": "text-align:center;padding-left:[textSpacing];padding-right:[textSpacing];",
    "%[data-state~=\"subItems-align-right\"] %_subMenu > %_item": "text-align:right;padding-left:[subItemAlterAlignPad];padding-right:[textSpacing];",
    "%[data-state~=\"subMenuOpenSide-right\"] %_subMenu": "left:100%;float:left;margin-left:[SKINS_submenuMargin];",
    "%[data-state~=\"subMenuOpenSide-right\"] %_subMenu:before": "left:0;margin-left:calc(-1 * [SKINS_submenuMargin]);",
    "%[data-state~=\"subMenuOpenSide-left\"] %_subMenu": "right:100%;float:right;margin-right:[SKINS_submenuMargin];",
    "%[data-state~=\"subMenuOpenSide-left\"] %_subMenu:before": "right:0;margin-right:calc(-1 * [SKINS_submenuMargin]);",
    "%[data-open-direction~=\"subMenuOpenDir-down\"] %_subMenu": "top:0;",
    "%[data-open-direction~=\"subMenuOpenDir-up\"] %_subMenu": "bottom:0;"
  }
}
 skins['wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuTextWithSeparatorsSkin'] = {
  "react": [
    [
      "ul",
      "menuContainer",
      [],
      {},
      [
        "li",
        "menuItem",
        [
          "_item",
          "_itemWrapper"
        ],
        {},
        [
          "a",
          null,
          [],
          {}
        ],
        [
          "ul",
          null,
          [
            "_subMenu"
          ],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "menuContainer": {
      "skin": "ComboBoxInputSkin",
      "dontMergeParams": true
    }
  },
  "params": {
    "sepw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "textSpacing": "PADDING_SIZE",
    "itemBGColorTrans": "TRANSITION",
    "bgh": "BG_COLOR_ALPHA",
    "txth": "TEXT_COLOR",
    "subMenuOpacityTrans": "TRANSITION",
    "bgs": "BG_COLOR_ALPHA",
    "txts": "TEXT_COLOR",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "SKINS_bgSubmenu": "BG_COLOR_ALPHA",
    "shd": "BOX_SHADOW",
    "SKINS_fntSubmenu": "FONT",
    "SKINS_submenuMargin": "MARGIN_SIZE",
    "subItemAlterAlignPad": "SIZE"
  },
  "paramsDefaults": {
    "sepw": "1px",
    "brd": "color_15",
    "textSpacing": "10px",
    "itemBGColorTrans": "background-color 0.4s ease 0s",
    "bgh": "color_15",
    "txth": "color_13",
    "subMenuOpacityTrans": "all 0.4s ease 0s",
    "bgs": "color_15",
    "txts": "color_13",
    "fnt": "font_1",
    "txt": "color_15",
    "SKINS_bgSubmenu": "color_11",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "SKINS_fntSubmenu": "font_1",
    "SKINS_submenuMargin": "0",
    "subItemAlterAlignPad": [
      "textSpacing"
    ]
  },
  "paramsMutators": {
    "subItemAlterAlignPad": {
      "type": "increase",
      "value": 0,
      "param": "textSpacing"
    }
  },
  "css": {
    "% > ul": "display:table;width:100%;box-sizing:border-box;",
    "% li": "display:table;width:100%;width:calc(100% - (2 * [textSpacing]));",
    "% a span": "pointer-events:none;",
    "%_noLink": "cursor:default !important;",
    "%_counter": "margin:0 10px;opacity:0.6;",
    "%menuContainer": "padding:0;margin:0;border-top:solid [sepw] [brd];border-bottom:solid [sepw] [brd];position:relative;",
    "%menuContainer %_emptySubMenu": "display:none !important;",
    "%_item": "border-bottom:solid [sepw] [brd];padding-left:[textSpacing];padding-right:[textSpacing];[itemBGColorTrans]  margin:0;position:relative;display:block;cursor:pointer;list-style:none;",
    "%_item:last-child": "border-bottom:0;",
    "%_item%_hover:not(%_noLink)": "background-color:[bgh];[itemBGColorTrans]",
    "%_item%_hover:not(%_noLink) > %_label": "color:[txth];",
    "%_item%_hover > %_subMenu": "opacity:1;[subMenuOpacityTrans]    display:block;",
    "%_item%_selected,%_item%_selectedContainer": "background-color:[bgs];[itemBGColorTrans]",
    "%_item%_selected > %_label,%_item%_selectedContainer > %_label": "color:[txts];",
    "%_label": "[fnt]  color:[txt];display:inline;white-space:nowrap;overflow:hidden;",
    "%_subMenu": "z-index:999;min-width:100%;opacity:0;display:none;position:absolute;border-top:solid [sepw] [brd];border-bottom:solid [sepw] [brd];[subMenuOpacityTrans]  background-color:[SKINS_bgSubmenu];[shd]",
    "%_subMenu %_label": "[SKINS_fntSubmenu]",
    "%_subMenu:before": "content:\" \";display:block;width:[SKINS_submenuMargin];height:100%;position:absolute;top:0;",
    "%[data-state~=\"items-align-left\"] %_item": "text-align:left;",
    "%[data-state~=\"items-align-center\"] %_item": "text-align:center;",
    "%[data-state~=\"items-align-right\"] %_item": "text-align:right;",
    "%[data-state~=\"subItems-align-left\"] %_subMenu > %_item": "text-align:left;padding-left:[textSpacing];padding-right:[subItemAlterAlignPad];",
    "%[data-state~=\"subItems-align-center\"] %_subMenu > %_item": "text-align:center;padding-left:[textSpacing];padding-right:[textSpacing];",
    "%[data-state~=\"subItems-align-right\"] %_subMenu > %_item": "text-align:right;padding-left:[subItemAlterAlignPad];padding-right:[textSpacing];",
    "%[data-state~=\"subMenuOpenSide-right\"] %_subMenu": "left:100%;float:left;margin-left:[SKINS_submenuMargin];",
    "%[data-state~=\"subMenuOpenSide-right\"] %_subMenu:before": "left:0;margin-left:calc(-1 * [SKINS_submenuMargin]);",
    "%[data-state~=\"subMenuOpenSide-left\"] %_subMenu": "right:100%;float:right;margin-right:[SKINS_submenuMargin];",
    "%[data-state~=\"subMenuOpenSide-left\"] %_subMenu:before": "right:0;margin-right:calc(-1 * [SKINS_submenuMargin]);",
    "%[data-open-direction~=\"subMenuOpenDir-down\"] %_subMenu": "top:calc(-1 * [sepw]);",
    "%[data-open-direction~=\"subMenuOpenDir-up\"] %_subMenu": "bottom:calc(-1 * [sepw]);"
  }
}

return skins;

});
